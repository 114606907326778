import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { commonRoutePaths } from '../utils/route-paths/common-route-paths';

/** Service manages redirects which needed on significant events. */
@Injectable({ providedIn: 'root' })
export class RedirectService {

	private readonly router = inject(Router);

	/** Redirect to auth page. */
	public redirectToAuthPage(): Promise<boolean> {
		return this.router.navigateByUrl(commonRoutePaths.auth.url, { replaceUrl: true });
	}

	/** Redirect user to home page. */
	public redirectToHomePage(): Promise<boolean> {
		return this.router.navigateByUrl(commonRoutePaths.portal.url, { replaceUrl: true });
	}
}
